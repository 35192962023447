export function getNamedContext (contextName = '', vm) {
  let context = null

  while (context === null && vm !== undefined && vm.$parent !== undefined) {
    if (vm.$options.name === contextName) context = vm
    else vm = vm.$parent
  }

  return context
}
