<template>
  <div class="registration">
    <template v-if="navigation.visibleKeys.includes(navigation.activeKey)">
      <div class="registration-header">
        <nav>
          <ol>
            <li v-for="key in navigation.visibleKeys" :key="key" :class="{ active: key === navigation.activeKey, disabled: !navigation.enabledKeys.includes(key) }">
              <a href="#" :disabled="!navigation.enabledKeys.includes(key)" @click="navigateTo(key)">{{ $T(`navigation.${key}`) }}</a>
            </li>
          </ol>
        </nav>
      </div>

      <div class="registration-body">
        <div v-if="navigationActiveIndex === 0" :class="`step-${navigation.keys[0]}`" ref="step">
          <div v-if="$slots['user-description']">
            <slot name="user-description"/>
            <hr/>
          </div>

          <b-row>
            <b-col :cols="$root.mobile.up.key">
              <h2 class="h3">{{ $T('formUser.Title') }}</h2>

              <formular-entity
                id="FormularUser"
                entityKey="users"
                :entityValues="metaData.user"
                @formular:init="$value => setFormData('user', $value)"
                @formular:change="$value => setFormData('user', $value)"
              />
            </b-col>

            <b-col :cols="$root.mobile.up.key" :class="['mt-4', `mt-${$root.mobile.up.key}-0`]">
              <h2 class="h3">{{ $T('formUserAddress.Title') }}</h2>

              <formular-entity
                id="FormularUserAddress"
                entityKey="useraddresses"
                :entityValues="metaData.userAddresses[0]"
                :definitionModifier="entityDefinitionModifier"
                @formular:init="$value => setFormData('userAddresses', $value)"
                @formular:change="$value => setFormData('userAddresses', $value)"
              />

              <h2 class="h3 mt-3">{{ $T('formProfession.Title') }}</h2>

              <formular-entity
                id="FormularUserProfessions"
                entityKey="userprofessions"
                :entityValues="metaData.professions[0]"
                @formular:init="$value => setFormData('professions', $value)"
                @formular:change="$value => setFormData('professions', $value)"
              />
            </b-col>
          </b-row>
        </div>

        <div v-if="navigationActiveIndex === 1" :class="`step-${navigation.keys[1]}`" ref="step">
          <div v-if="$slots['location-index-description'] && !formNewLocationShow">
            <slot name="location-index-description"/>
            <hr/>
          </div>

           <div v-if="$slots['location-description'] && formNewLocationShow">
            <slot name="location-description"/>
            <hr/>
          </div>

          <formular-locations
            :initialValues="[].concat(metaData.userLocations || [], metaData.locationsWithAddresses || [])"
            :disabled="metaData.registerWithoutAddresses"
            :translations="TRANSLATIONS.formlocation"
            @formular:init="$value => setFormData($value.formProperty, $value, $value.modify)"
            @formular:change="$value => setFormData($value.formProperty, $value, $value.modify)"
            @formular:addlocationform-open="() => formNewLocationShow=true"
            @formular:addlocationform-close="() => formNewLocationShow=false"
          />

          <div class="mt-3">
            <control-checkbox
              id="RegisterWithoutAddress"
              class="mb-0"
              v-model="metaData.registerWithoutAddresses"
              :text="$T('formLocation.RegisterWithoutAddresses')"
              @change="onChangeRegistrationWithoutAddresses"
            />
          </div>

          <div class="mt-3" v-if="(formData.userLocations || []).length > 0">
            <hr />
            <p>{{ $T('formLocation.EmailNotificationHint') }}</p>
          </div>
        </div>

        <div v-if="navigationActiveIndex === 2" :class="`step-${navigation.keys[2]}`" ref="step">
          <div v-if="$slots['login-description']">
            <slot name="login-description"/>
            <hr/>
          </div>

          <b-row>
            <b-col :cols="$root.mobile.up.key">
              <h2 class="h3">{{ $T('formLogin.Title') }}</h2>

              <b-alert v-if="metaData.docCheckLoginId" show>
                <b-icon icon="b-info-circle" /> {{ $T('formLogin.DocCheckLoginMessage') }}
              </b-alert>

              <formular-entity
                id="FormularLogin"
                :entityDefinition="loginDefinition"
                :entityValues="metaData.login"
                @formular:init="$value => setFormData('login', $value)"
                @formular:change="$value => setFormData('login', $value)"
              />
            </b-col>

            <b-col :cols="$root.mobile.up.key" :class="['mt-4', `mt-${$root.mobile.up.key}-0`]">
              <h2 class="h3">{{ $T('whatsNext.Title') }}</h2>
              <div v-html="$T('whatsNext.Html')"/>
            </b-col>
          </b-row>
        </div>
      </div>

      <div class="registration-footer">
        <b-row align-v="center" align-h="between">
          <b-col cols="auto">
            <b-button class="p-0" variant="link" v-if="!navigationPrevIsDisabled" @click="navigateTo(navigation.modifier.backward)">{{ $T('navigation.prev') }}</b-button>
          </b-col>

          <b-col cols="auto">
            <b-button variant="primary" @click="navigationNextIsDisabled ? validateStep() : navigateTo(navigation.modifier.forward)">{{ $T(navigation.activeKey === navigation.submitKey ? 'navigation.Submit' : 'navigation.Next') }}</b-button>
          </b-col>
        </b-row>
      </div>
    </template>

    <template v-else>
      <div class="registration-body">
        <div v-if="!completionData" class="loader-wrapper empty">
          <c-loader/>
        </div>

        <template v-else>
          <slot name="completion" v-bind="completionData"/>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { ADDRESS_TYPES, CONTROL_COMPONENT_TO_DISCRIMINATOR_MAP, REGISTRATION_FORMDATA_DEFAULTS, LOCATIONSSEARCH_PROPERTY_OPTIONS, STOREMODULE_ENDPOINT_MAP } from '@/assets/js/config/server'
import { REGISTRATION_STEPS } from '@/assets/js/config/client'

import { copy } from '@/assets/js/helper/object'
import { getSearchParameters } from '@/assets/js/helper/url'
import { getNamedContext } from '@/assets/js/helper/vm'
import { validators } from '@/$plugins/vuelidate'

import FormularEntity from '@/components/registration/FormularEntity'
import FormularLocations from '@/components/registration/FormularLocations'

const NAVIGATION_MODIFIER = { backward: -1, forward: 1 }
const NAVIGATION_KEYS = Object.keys(REGISTRATION_STEPS)
const ENTITY_ID_KEYS = ['id'].concat(Object.values(LOCATIONSSEARCH_PROPERTY_OPTIONS.idProperties))
const INVITATION_CODE = getSearchParameters('code')

export default {
  name: 'Registration',
  components: {
    FormularEntity,
    FormularLocations
  },
  data () {
    return {
      navigation: {
        modifier: NAVIGATION_MODIFIER,
        keys: NAVIGATION_KEYS,
        activeKey: NAVIGATION_KEYS[0],
        submitKey: NAVIGATION_KEYS.find(key => REGISTRATION_STEPS[key].isSubmitStep),
        visibleKeys: NAVIGATION_KEYS.filter(key => REGISTRATION_STEPS[key].showInNavigation),
        enabledKeys: [NAVIGATION_KEYS[0]]
      },
      tempData: {},
      metaData: Object.assign(copy(REGISTRATION_FORMDATA_DEFAULTS), { invitationCode: INVITATION_CODE, docCheckLoginId: undefined, registerWithoutAddresses: false }),
      formData: copy(REGISTRATION_FORMDATA_DEFAULTS),
      completionData: null,
      formNewLocationShow: false
    }
  },
  computed: {
    loginDefinition () {
      const LANGUAGE_KEY = this.$store.getters['gui/getLanguage']

      return {
        properties: [
          {
            attributeTypeDiscriminator: CONTROL_COMPONENT_TO_DISCRIMINATOR_MAP.input.type,
            name: 'email',
            settings: {
              replacedByDocCheckLoginId: true,
              disabled: true,
              validators: ['emailValidator', 'requiredValidator']
            },
            translations: {
              [LANGUAGE_KEY]: {
                name: this.$T('formLogin.Control.Email.Label')
              }
            }
          },
          {
            attributeTypeDiscriminator: CONTROL_COMPONENT_TO_DISCRIMINATOR_MAP.password.type,
            name: 'password',
            settings: {
              replacedByDocCheckLoginId: true,
              autocomplete: 'new-password'
            },
            translations: {
              [LANGUAGE_KEY]: {
                name: this.$T('formLogin.Control.Password.Label'),
                feedbacksInvalid: {
                  lowercase: this.$T('formLogin.Control.Password.FeedbackInvalid.LowerCase'),
                  uppercase: this.$T('formLogin.Control.Password.FeedbackInvalid.UpperCase'),
                  digit: this.$T('formLogin.Control.Password.FeedbackInvalid.Digit'),
                  nonalphanumeric: this.$T('formLogin.Control.Password.FeedbackInvalid.NonAlphaNumeric'),
                  nonundefined: this.$T('formLogin.Control.Password.FeedbackInvalid.NonUndefined'),
                  minLength: this.$T('formLogin.Control.Password.FeedbackInvalid.MinLength')
                }
              }
            }
          },
          {
            attributeTypeDiscriminator: CONTROL_COMPONENT_TO_DISCRIMINATOR_MAP.passwordConfirm.type,
            name: 'password_confirm',
            settings: {
              replacedByDocCheckLoginId: true,
              autocomplete: 'off',
              validators: ['requiredValidator'],
              customValidators: {
                sameAsPassword: {
                  validation: validators.sameAs,
                  parameters (control = {}) {
                    const context = getNamedContext('Formular', this)
                    return context ? context.form.controls.password.control.value : control.value
                  }
                }
              }
            },
            translations: {
              [LANGUAGE_KEY]: {
                name: this.$T('formLogin.Control.PasswordConfirm.Label'),
                feedbacksInvalid: {
                  sameAsPassword: this.$T('formLogin.Control.PasswordConfirm.FeedbackInvalid.SameAsPassword')
                }
              }
            }
          },
          {
            attributeTypeDiscriminator: CONTROL_COMPONENT_TO_DISCRIMINATOR_MAP.boolean.type,
            name: 'data_correctness',
            settings: {
              validators: ['requiredValidator']
            },
            translations: {
              [LANGUAGE_KEY]: {
                name: this.$T('formLogin.Control.DataCorrectness.Label')
              }
            }
          },
          {
            attributeTypeDiscriminator: CONTROL_COMPONENT_TO_DISCRIMINATOR_MAP.boolean.type,
            name: 'registration_conditions',
            settings: {
              validators: ['requiredValidator']
            },
            translations: {
              [LANGUAGE_KEY]: {
                name: this.$T('formLogin.Control.RegistrationConditions.Label')
              }
            }
          }
        ]
          .filter(p => !this.metaData.docCheckLoginId || (this.metaData.docCheckLoginId && !p.settings.replacedByDocCheckLoginId))
      }
    },
    navigationActiveIndex () {
      return this.navigation.keys.indexOf(this.navigation.activeKey)
    },
    navigationPrevIsDisabled () {
      return this.navigationActiveIndex === 0
    },
    navigationNextIsDisabled () {
      return !this.navigation.enabledKeys.includes(this.navigation.keys[this.navigationActiveIndex + 1])
    }
  },
  methods: {
    getInitialData () {
      if (this.metaData.invitationCode) {
        this.$http({
          method: 'get',
          url: `/customer/api/Invitation/${this.metaData.invitationCode}`
        })
          .then(response => {
            this.metaData = Object.assign(this.metaData, response.data.result.invitationData)

            Object.keys(this.formData)
              .forEach(dKey => {
                this.formData[dKey] = copy(this.metaData[dKey])
              })
          })
          .catch(() => {})
          .finally(() => {
            this.overwriteFormDataByMetaOptions()
          })
      } else {
        this.overwriteFormDataByMetaOptions()
      }
    },
    // start FDFC - fast and dirty frontend customization
    entityDefinitionModifier (definition = {}) {
      if (definition.name === STOREMODULE_ENDPOINT_MAP.useraddresses) {
        const property = definition.properties.find(p => p.name === 'type')

        property.settings.defaultValue = ADDRESS_TYPES.default
        property.settings.hidden = true
        definition.$properties.type.settings.defaultValue = ADDRESS_TYPES.default
        definition.$properties.type.settings.hidden = true
      }

      return definition
    },
    // end FDFC
    setMetaData () {
      this.metaData = Object.assign(this.metaData, copy(this.formData), copy(this.tempData))
      this.overwriteFormDataByMetaOptions()
    },
    setFormData (formProperty = '', formData = {}, modify = null) {
      const DATA_DEFAULT = copy(REGISTRATION_FORMDATA_DEFAULTS[formProperty])

      if (formData.isValid) {
        if (Array.isArray(DATA_DEFAULT)) {
          const ID_PROPERTY_REGEX = new RegExp(`^(${ENTITY_ID_KEYS.join('|')})$`)
          const ID_KEY = Object.keys(formData.controls).find(cKey => ID_PROPERTY_REGEX.test(cKey))

          if (modify === true) {
            const existingIndex = this.formData[formProperty].findIndex(d => d[ID_KEY] === formData.controls[ID_KEY])

            this.$set(this.formData[formProperty], existingIndex >= 0 ? existingIndex : this.formData[formProperty].length, formData.controls)
          } else if (modify === false) {
            this.$set(this.formData, formProperty, this.formData[formProperty].filter(d => d[ID_KEY] !== formData.controls[ID_KEY]))
          } else {
            this.$set(this.formData, formProperty, [formData.controls])
          }
        } else {
          this.$set(this.formData, formProperty, formData.controls)
        }
      } else {
        this.formData[formProperty] = DATA_DEFAULT
      }

      this.setEnabledNavigationKeys()
      this.overwriteFormDataByMetaOptions()
    },
    setEnabledNavigationKeys () {
      const firstInvalidIndex = this.navigation.keys.findIndex(key => !(REGISTRATION_STEPS[key].validation || function () { return false })(this.formData, this.metaData))
      this.navigation.enabledKeys = this.navigation.keys.filter((f, i) => i <= firstInvalidIndex)
    },
    overwriteFormDataByMetaOptions () {
      if (this.metaData.invitationCode) this.formData.invitationCode = this.metaData.invitationCode
      if (this.metaData.docCheckLoginId) this.formData.login.docCheckId = this.metaData.docCheckLoginId
      if (!this.metaData.docCheckLoginId) this.metaData.login.email = this.formData.user.email

      if (this.metaData.registerWithoutAddresses) {
        this.formData.userLocations = copy(REGISTRATION_FORMDATA_DEFAULTS.userLocations)
        this.formData.locationsWithAddresses = copy(REGISTRATION_FORMDATA_DEFAULTS.locationsWithAddresses)
      }
    },
    validateStep () {
      Array.from(this.$refs.step.querySelectorAll('form') || [])
        .map(form => form.id)
        .forEach(id => {
          this.$root.$emit(`${id}:validate`)
        })
    },
    navigateTo (key = null) {
      if (this.navigation.activeKey === this.navigation.submitKey && key === this.navigation.modifier.forward && !this.navigationNextIsDisabled) {
        this.onSubmit()
      }

      if (Object.values(this.navigation.modifier).includes(key)) key = this.navigation.keys[this.navigationActiveIndex + key]
      if (this.navigation.enabledKeys.includes(key)) {
        if (this.navigation.activeKey !== key) this.setMetaData()
        this.navigation.activeKey = key
      }
    },
    onChangeRegistrationWithoutAddresses () {
      if (this.metaData.registerWithoutAddresses) {
        this.tempData.userLocations = copy(this.formData.userLocations)
        this.tempData.locationsWithAddresses = copy(this.formData.locationsWithAddresses)
        this.setMetaData()
        this.formData.userLocations = copy(REGISTRATION_FORMDATA_DEFAULTS.userLocations)
        this.formData.locationsWithAddresses = copy(REGISTRATION_FORMDATA_DEFAULTS.locationsWithAddresses)
      } else {
        this.formData.userLocations = copy(this.tempData.userLocations || REGISTRATION_FORMDATA_DEFAULTS.userLocations)
        this.formData.locationsWithAddresses = copy(this.tempData.locationsWithAddresses || REGISTRATION_FORMDATA_DEFAULTS.locationsWithAddresses)
        this.setMetaData()
        delete this.tempData.userLocations
        delete this.tempData.locationsWithAddresses
      }

      this.setEnabledNavigationKeys()
    },
    onSubmit () {
      this.$http({
        method: 'post',
        url: '/customer/api/Registration',
        data: this.formData
      })
        .then(response => {
          this.completionData = this.composeCompletionData(response.data)
        })
        .catch(errorResponse => {
          this.completionData = this.composeCompletionData(errorResponse.response.data)
        })
    },
    composeCompletionData (response) {
      const LANGUAGE_KEY = this.$store.getters['gui/getLanguage']
      const result = {
        language: LANGUAGE_KEY,
        result: response.result,
        navigateBack: () => {
          this.completionData = null
          this.navigateTo(this.navigation.modifier.backward)
        },
        success: response.success
      }

      return result
    }
  },
  created () {
    this.getInitialData()
  }
}
</script>

<style lang="scss">
$registration-border-radius: $leaf-border-radius !default;
$registration-bg: $white !default;

$registration-header-padding-y: $breadcrumb-padding-y !default;
$registration-header-padding-x: $component-padding-x !default;
$registration-header-bg: $primary !default;

$registration-body-padding-y: $component-padding-y !default;
$registration-body-padding-x: $component-padding-x !default;
$registration-body-bg: null !default;

$registration-footer-padding-y: $component-padding-y !default;
$registration-footer-padding-x: $component-padding-x !default;
$registration-footer-bg: null !default;

$registration-navigation-item-gap: $breadcrumb-item-padding !default;
$registration-navigation-item-divider: $breadcrumb-divider !default;
$registration-navigation-item-font-size: $breadcrumb-font-size !default;
$registration-navigation-item-font-weight: inherit !default;
$registration-navigation-item-color: $white !default;

$registration-navigation-item-disabled-font-size: $registration-navigation-item-font-size !default;
$registration-navigation-item-disabled-font-weight: $registration-navigation-item-font-weight !default;
$registration-navigation-item-disabled-color: theme-color-level('primary', -7) !default;

$registration-navigation-item-active-font-size: $registration-navigation-item-font-size !default;
$registration-navigation-item-active-font-weight: $font-weight-bold !default;
$registration-navigation-item-active-color: $registration-navigation-item-color !default;

.registration {
  @include make-leaf($registration-border-radius);
  background-color: $registration-bg;

  .registration-header {
    @include make-leaf($registration-border-radius, 'top');
    padding: $registration-header-padding-y $registration-header-padding-x;
    background-color: $registration-header-bg;

    nav {
      ol {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          display: flex;
          flex-wrap: nowrap;
          font-size: $registration-navigation-item-font-size;
          font-weight: $registration-navigation-item-font-weight;
          color: $registration-navigation-item-color;

          a {
            color: inherit;
            text-decoration: none;
          }

          &.disabled {
            font-size: $registration-navigation-item-disabled-font-size;
            font-weight: $registration-navigation-item-disabled-font-weight;
            color: $registration-navigation-item-disabled-color;

            a {
              cursor: default;
            }
          }

          &.active {
            font-size: $registration-navigation-item-active-font-size;
            font-weight: $registration-navigation-item-active-font-weight;
            color: $registration-navigation-item-active-color;
          }

          + li {
            padding-left: $registration-navigation-item-gap;

            &:before {
              float: left;
              content: $registration-navigation-item-divider;
              padding-right: $registration-navigation-item-gap;
            }
          }
        }
      }
    }
  }

  .registration-body {
    padding: $registration-body-padding-y $registration-body-padding-x;
    background-color: $registration-body-bg;
  }

  .registration-footer {
    @include make-leaf($registration-border-radius, 'bottom');
    padding: $registration-footer-padding-y $registration-footer-padding-x;
    background-color: $registration-footer-bg;
    border-top: $border-width $border-style $border-color;
  }
}
</style>
